import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/trabalho1.svg" style={{height: '40px', marginBottom: '10px'}}/>Para exercício de actividade profissional subordinada.</h3>
      </div>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/trabalho2.svg" style={{height: '40px', marginBottom: '10px'}}/>Para exercício de actividade profissional independente.</h3>
      </div>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/investigacao.svg" style={{height: '40px', marginBottom: '10px'}}/>Para actividade de investigação ou altamente qualificada.</h3>
      </div>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/1-2.svg" style={{height: '40px', marginBottom: '10px'}}/>Para actividade de investimento.</h3>
      </div>
      
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/ensino secundario.svg" style={{height: '40px', marginBottom: '10px'}}/>Para estudo em estabelecimento de ensino secundário.</h3>
      </div>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/ensino superior.svg" style={{height: '40px', marginBottom: '10px'}}/>Para estudo em estabelecimento de ensino superior.</h3>
      </div>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/intern.svg" style={{height: '40px', marginBottom: '10px'}}/>Para estagiários não remunerados.</h3>
      </div>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/volunteer.svg" style={{height: '40px', marginBottom: '10px'}}/>Para voluntariados.</h3>
      </div>
      <div className="empresas-hub">
        <h3><img src="assets/images/icons/family.svg" style={{height: '40px', marginBottom: '10px'}}/>Para reagrupamento familiar.</h3>
      </div>
      
    </Slider>
  );
}