import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Planeta Milionário<br/>Unipessoal, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Solomon Corporation<br/>Unipessoal, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Positivemirror<br/>Unipessoal, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Várzea Encantada<br/>Unipessoal, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Segundos Emblemáticos<br/>Logistica & Transportes, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Efeito Emblemático<br/>Design e Fabrico Móveis, Unipessoal, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Estilo Imaculado<br/>Consulting Unipessoal, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>Área Coberta<br/>Sociedade de Mediação Imobiliária, Lda.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-building-o" aria-hidden="true"></i><br/>APAFLOC<br/>Associação dos Proprietários Agro Florestais da Comporta</h3>
      </div>
    </Slider>
  );
}