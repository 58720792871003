import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://sdr-businessacademy.com" target="_self">
                            <img src="/assets/images/brand/sdr (2).jpg" alt="SDR LAB"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://sdr-businessacademy.com" target="_self">
                        <img src="/assets/images/brand/sdr (1).jpg" alt="SDR Business Academy"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://sdr-businessacademy.com" target="_self">   
                        <img src="/assets/images/brand/sdr (3).jpg" alt="SDR HUB"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://bestauctionworld.com" target="_blank">
                        <img src="/assets/images/brand/logo (1).jpg" alt="Best Auction World"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://selosdomundo.com" target="_blank">
                        <img src="/assets/images/brand/logo (1).png" alt="Selos do Mundo"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://thenannyworld.com" target="_blank">
                        <img src="/assets/images/brand/logo (2).jpg" alt="The Nanny World"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://brazilianfashionstyle.com" target="_blank">
                        <img src="/assets/images/brand/logo (3).jpg" alt="Brazilian Fashion Style"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://planetaimprevisivel.com" target="_blank">
                        <img src="/assets/images/brand/logo (4).jpg" alt="Planeta Imprevisível"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://numerosvirtuosos.com" target="_blank">
                        <img src="/assets/images/brand/logo (5).jpg" alt="Números Virtuosos"/>
                        
                        </a>
                    </li>
                    <li>
                    <a href="https://wildcolourstours.com" target="_blank">
                        <img src="/assets/images/brand/logo (2).png" alt="Wild Colours Tours"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://equacaocristalina.com" target="_blank">
                        <img src="/assets/images/brand/logo (3).png" alt="Equação Cristalina"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://equacaocristalina.com" target="_blank">
                        <img src="/assets/images/brand/greenmobility-400.png" alt="Green Mobility"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://equacaocristalina.com" target="_blank">
                        <img src="/assets/images/brand/renewable-400.png" alt="Renewable"/>
                        </a>
                    </li>
                    
                    <li>
                    <a href="https://respostacoincidente.com" target="_blank">
                        <img src="/assets/images/brand/logo (4).png" alt="Resposta Coincidente"/>
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;