import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import CookieConsent from "react-cookie-consent";


const SlideList = [
    
    {
        textPosition: 'text-left',
        bgImage: 'bg03',
        category: '',
        title: 'Hub',
        description: 'Espaço dedicado a estabelecer conexões e gerar negócios.',
        buttonText: 'Saiba mais',
        buttonLink: '/hub',
        overlay: 6,
    },
    
    {
        textPosition: 'text-right',
        bgImage: 'bg02',
        category: '',
        title: 'Lab',
        description: 'A sua ideia pode mudar o futuro. Conte connosco.',
        buttonText: 'Incubação de empresas',
        buttonLink: '/lab',
        overlay:2,
    },
    {
        textPosition: 'text-center botao-brevemente',
        bgImage: 'business2-v',
        category: '',
        title: 'Business Academy',
        description: 'Estamos a preparar as suas ações de formação e workshops. Acompanhe o nosso site e não perca nenhuma actualização.',
        buttonText: 'Brevemente disponível',
        buttonLink: '#',
        overlay: 1,
    },
    
]


const starndardService = [
    {
        image: 'bg01-min',
        title: 'SDR Business Academy',
        description: 'Cursos de Formação e Workshops para ajudar empreendedores a terem formação em diferentes áreas e aspetos necessários à gestão da sua empresa. Acompanhe o nosso site e não perca nenhuma actualização.',
        botao: 'Brevemente Disponível',
        exibir: 'nao-exibir',
    },
    {
        image: 'bg03-min',
        title: 'SDR Hub',
        description: 'Espaço de colaboração, acolhimento e sinergias para potenciar as tuas ideias e desenvolver projetos.',
        botao: 'Iniciar colaboração',
        buttonLink: '/hub',
    },
    {
        image: 'bg02-min',
        title: 'SDR Lab',
        description: 'Programas e recursos para promover o pensamento empreendedor e o nascimento de ideias de negócios.',
        botao: 'Desenvolver minha empresa',
        buttonLink: '/lab',
    },

]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="SDR Business Academy" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}  data-black-overlay={`${value.overlay}`}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description" style={{opacity: "1"}}>{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                

                <div className=" service-home-bg">              
                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5 fadeInDown" style={{background:'unset'}}>
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-12 col-12 mt--30">
                                <div className="section-title services-home">
                                    <h3 style={{lineHeight: 1.3}}>Academia e Incubadora<br/><hr style={{margin:"20px 0px",borderBottom:"2px solid white", maxWidth:"80%"}} /> Inovação e Negócios</h3>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/service/${value.image}.jpg`} alt="Corporate Images"/>
                                                </div>
                                                <div className="content fadein-class">
                                                    <h3><a href="/service-details">{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className={`btn-transparent rn-btn-dark ${value.exibir}`} href={`${value.buttonLink}`}><span className="text">{value.botao}</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}
                
                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 theme-text-white bg-count-home" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}


                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120" style={{background:'white'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div style={{textAlign:'-webkit-center', marginBottom: '40px'}}>
                                    <h3 style={{lineHeight: 1.3, textAlign:"center", color:'#4a4a4a',fontSize:'36px', marginBottom: '07px'}}>O nosso grupo empresarial</h3>
                                    {/* <p style={{maxWidth:"650px"}}>Procurando no dia-a-dia o seu crescimento<br/>através de oportunidades de negócio.</p> */}
                                    <hr style={{margin:"10px 0px",borderBottom:"2px solid #4a4a4a", width:"80%", maxWidth:'200px'}} /> 
                                </div>
                                <BrandTwo />
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */} 
                </div> 
                <div style={{textAlign: '-webkit-center', position: 'relative', zIndex: '3', background: '#e9e9e9'}}><img src="/assets/images/icons/Imagem2.png" style={{maxWidth:"700px", width: '100%', padding: '10px 0'}}></img></div>
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}
                <CookieConsent buttonText="Aceito">Este site utiliza cookies para permitir uma melhor experiência por parte do utilizador. Ao navegar no site estará a consentir a sua utilização. <a href="/politicas-privacidade">Políticas de Privacidade</a></CookieConsent>
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;