import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class Accordion01 extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'a'} >
                <AccordionItem uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        SEGURANÇA <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel >
                        <p>
                        Portugal é o <strong>4º país mais seguro do mundo</strong> e o <strong>2º mais pacífico da União Europeia</strong>. Um dos principais motivos para a escolha de se viver em um país é, sem dúvida, a segurança. Por isso, a segurança em Portugal tem sido o ponto forte do país para que muitas famílias estrangeiras o escolham para viver. Em 2019 e 2020, Portugal alcançou o 3º lugar na lista de países mais pacíficos do mundo, segundo o Global Peace Index (Índice Global da Paz).
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        OPORTUNIDADES <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Portugal é um país <strong>altamente desenvolvido</strong> e tem sido o ninho de algumas inovações de alta tecnologia que mudaram o mundo em que vivemos hoje.<br/>O Multibanco - uma das melhores redes bancárias do mundo; Via Verde - o primeiro sistema fechado de portagem automático do mundo; Os telemóveis pré-pagos - que rapidamente se tornaram a base da revolução móvel.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        CLIMA <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Portugal Continental<br/>
                        O clima em Portugal varia significativamente de região para região e é influenciado pelo relevo, latitude e proximidade do mar, que proporciona Invernos suaves, especialmente no Algarve.<br/>
                        Nas áreas do Porto, Norte e Centro, especialmente nas zonas mais próximas de Espanha, os Invernos são mais frios, apesar das temperaturas serem moderadas quando comparadas com o resto da Europa. Regista-se alguma queda de neve, que é mais frequente na Serra da Estrela, onde se situa o ponto mais alto de Portugal Continental (1991m) e se podem encontrar condições para a prática de ski. Os verões são quentes e secos sobretudo nas regiões do interior e no litoral. O calor é moderado pela influência marítima. Durante o outono registam-se frequentemente dias ensolarados com temperaturas amenas.<br/><br/>
                        Açores<br/>
                        Influenciado pela latitude e pela ação reguladora da corrente do Golfo, o clima dos Açores é caraterizado por temperaturas amenas ao longo de todo o ano. Estas influências condicionam igualmente a temperatura da água do mar, que se mantém muito agradável tanto no inverno como no verão, possibilitando a prática de diversos desportos marítimos.<br/><br/>
                        Madeira<br/>
                        Com características subtropicais que se devem à sua posição geográfica e ao relevo montanhoso, o clima no arquipélago da Madeira é excecionalmente ameno, com temperaturas médias do ar que variam entre os 24 ºC no verão e os 19 ºC no inverno. A água do mar mantém igualmente uma temperatura muito agradável ao longo de todo o ano devido à influência da corrente quente do Golfo, oscilando entre os 18 ºC (inverno) e os 22 ºC (verão).</p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}
export default Accordion01;





