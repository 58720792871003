import React, { Component } from "react";
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import Slick from "../blocks/Slick03";
import ContactForm from "../elements/contact/ContactForm2";


const ServiceListOne = [
    
    {
        icon: '1',
        title: 'Transferência de Capital',
        description: 'Transferência mínima de 1.000.000€ para Portugal',
    },
    {
        icon: '2',
        title: 'Imobiliário',
        description: 'Investimento mínimo de 500.000€ em qualquer propriedade em Portugal',
    },
    {
        icon: '3',
        title: 'Imóveis em Zonas de Baixa Densidade',
        description: 'Investimento mínimo de 400.000€ em qualquer propriedade em zonas portuguesas de baixa densidade',
    },
    {
        icon: '4',
        title: 'Reabilitação Urbana',
        description: 'Investimento mínimo de 350.000€ num imóvel com mais de 30 anos em zona de reabilitação urbana e remodelá-lo',
    },
    {
        icon: '5',
        title: 'Reabilitação Urbana em Zona de Baixa Densidade',
        description: 'Investimento mínimo de 280.000€ num imóvel com mais de 30 anos em zona de reabilitação urbana com baixa densidade e remodelá-lo',
    },
    {
        icon: '6',
        title: 'Criação de 10 Empregos a Tempo Integral',
        description: 'Criação de 10 empregos a tempo integral em empresa portuguesa de sua propriedade (apenas 8 empregos a tempo inteiro necessários em zonas de baixa densidade)',
    },
    {
        icon: '7',
        title: 'Investimento em uma Empresa Portuguesa',
        description: 'Investimento mínimo de 350.000€ mais a criação de 5 postos de trabalho a tempo inteiro durante pelo menos 3 anos, numa empresa portuguesa existente',
    },
    {
        icon: '8',
        title: 'Investimento em Pesquisa',
        description: 'Investimento mínimo de 350.000€ para pesquisa em institutos certificados no sistema tecnológico e científico nacional',
    },
    {
        icon: '9',
        title: 'Investimento no Património Nacional',
        description: 'Investimento mínimo de 250.000€ na preservação do património, das artes e da cultura portuguesas',
    },
    {
        icon: '10',
        title: 'Fundos Privados',
        description: 'Investimento mínimo de 350.000€ num fundo privado português',
    }
]

class PortugalDetails extends Component{
    
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        document.body.classList.remove('lab-class')
        document.body.classList.remove('hub-class')
        document.body.classList.remove('golden-class')
        document.body.classList.add('portugal-class')
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Helmet  */}
                <Helmet pageTitle='Custos e Vantagens Portugal - Golden Visa' />
                {/* End Helmet  */}

                <Header headerPosition="header--static logoresize logo-hub" logo="all-dark" color="color-black" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--150 pb--190 bg_image bg-image-hub " style={{backgroundImage: 'url(/assets/images/bg/ponte-portugal.jpg)'}} data-black-overlay="7" >
                    <div className="container">
                        <h2 className="title solange" style={{lineHeight:'1.2'}}>Investir em Portugal - Custos e Vantagens</h2>
                        <h4 style={{lineHeight:1.3, color: "white", fontWeight: '300', fontSize: '30px', paddingLeft: '10px', fontStyle: 'italic'}}>Entenda o processo e vantagens de forma detalhada</h4>
                    </div>
                </div>
                <div className="container">
                    <div className="row fadein-class acoes-rapidas-hub">
                            <div className="col-lg-3 col-sm-12 offset-lg-1" style={{background: 'linear-gradient(45deg, #ffd082, #c48840)'}}>
                                <a href="/golden-visa-portugal">
                                    <h4 style={{color: 'white'}}><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Obter Golden Visa</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: '#9ac23a'}}>
                                <a href="#mais-informacoes">
                                    <h4 style={{color: 'white'}}><i className="fa fa-plus-square" aria-hidden="true"></i> Mais informações</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: 'linear-gradient(315deg, #ffd082, #c48840)'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-headphones" aria-hidden="true"></i> Nossos contactos</h4>
                                </a>
                            </div>
                        </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start custos Area */}
                <div className="service-area ptb--30 bg_color--1 pt--100 pb--60 servicos-quemsomos" style={{backgroundColor: 'transparent'}} id="mais-informacoes">
                    <div className="container">
                        <div className="row service-one-wrapper services-investimento" style={{alignContent: 'center',alignItems: 'center', justifyContent: 'center'}}>
                            <div className="col-12" style={{marginBottom: '50px'}}>
                                <h2 className="title solange" style={{marginTop: '15px', lineHeight:'0.9'}}>Tipos de Investimento</h2>
                                <p style={{fontWeight: '300',textAlign: 'justify'}}>Embora a forma mais comum de obtê-lo seja através de um investimento imobiliário, existem outras formas de obter o seu <a href="/golden-visa-portugal" style={{fontWeight: 600, color: 'goldenrod'}}>Golden Visa</a> em Portugal, vamos explicá-las abaixo, para que possa escolher a mais adequada à sua situação. Em qualquer caso, pode sempre falar connosco, e juntos faremos a melhor escolha.</p>
                            </div>

                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <img src={`/assets/images/icons/${val.icon}.svg`} style={{width: '45px'}} />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="row service-one-wrapper">
                            <h4 className="title hub-especializado" style={{fontWeight: '400', marginBottom: '30px', fontStyle: 'italic'}}>Custos:</h4>
                            <table>

                                <th>Governamentais</th>
                                <tr><td>Candidato principal</td><td>550€</td></tr>
                                <tr><td>Cada dependente</td><td>80€</td></tr>
                                <tr><td>Autorização de Residência Inicial para o investidor</td><td>5.150€</td></tr>
                                <tr><td>Autorização de Residência Inicial por cada membro da família do investidor</td><td>5.150€</td></tr>
                            </table>
                        </div>
                    </div>
                </div>
                {/* End custos Area */}

                {/* Start porque portugal Area  */}
                <div className="rn-about-area ptb--50 bg_color--5 porque-portugal" style={{background: "transparent"}}>
                    <div className="container">
                        <div className="row" style={{marginBottom: '30px'}}>
                            <div className="col-12">
                                <h2 className="title solange" style={{marginTop: '15px', lineHeight:'0.9'}}>Porquê Portugal?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <Slick/>
                    </div>
                </div>
                {/* End About Area  */}
                
                {/* Start Single Area */}
                <div className="container">
                    <div className="row sercice-details-content align-items-start pt--60 pb--80 contact-form--1 contato-golden-visa" id="golden-visa">
                                        <div className="col-lg-6 order-2 order-lg-1">
                                            <div className="section-title text-left mb--50">
                                                <h4 className="title hub-especializado" style={{fontSize: '30px'}}>Entre em contacto</h4>
                                                <p className="description" style={{fontStyle: 'italic'}}>Viva em um dos países mais seguros do mundo e seja livre para viver a vida com seus entes queridos.</p>
                                            </div>
                                            <div className="form-wrapper">
                                                <ContactForm />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 order-1 order-lg-2">
                                            <div className="thumbnail mb_md--30 mb_sm--30" >
                                                <img src="/assets/images/bg/portugal-golden-contacto.jpg" alt="São Domingos de Rana Business Academy"/>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                        {/* End Single Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default PortugalDetails;