import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
// import Slider from "react-slick";
// import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck } from "react-icons/fi";
// import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";


// const SlideList = [
//     {
//         textPosition: 'text-right',
//         bgImage: 'bg_image--32',
//         category: '',
//         title: 'Business.',
//         description: 'There are many variations of passages but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     },
//     {
//         textPosition: 'text-left',
//         bgImage: 'bg_image--31',
//         category: '',
//         title: 'Agency.',
//         description: 'There are many variations of passages but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     },
    
// ]

const ServiceListOne = [
    {
        icon: <FiCast />,
        title: 'Crescimento Empresarial'
    },
    {
        icon: <FiLayers />,
        title: 'Potencial de Negócios'
    },
    {
        icon: <FiUsers />,
        title: 'Gestão de Pessoas'
    },
]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="SDR Business Academy" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Motivar Area  */}
                <div className="rn-about-area ptb--120 bg_color--5 about-quemsomos" style={{backgroundColor: "#0303bf", paddingBottom: "30px"}}>
                    <div className="container">
                        
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title" style={{marginBottom: "40px"}}>Motivar Outros</h2>
                                        <p>Eu não conheço ninguém que tenha chegado ao topo sem muito trabalho. Essa é a receita. Nem sempre você vai chegar ao topo, mas vai chegar bem perto.</p>

                                        <p>As pessoas pensam que o sucesso chega de uma forma fácil, mas se você é um empreendedor, sabe quanto trabalho duro o seu negócio envolve e o sacrifício que faz na sua vida pessoal. Ser empreendedor tem sempre um preço. A solução é usar cada desafio que tem, como uma fonte de motivação.</p>

                                        <p>A única coisa que devemos cobrar ao mundo é uma oportunidade. Quando chega essa oportunidade, temos a responsabilidade de inspirar os outros a também terem sucesso.</p>

                                        <p>Tudo o que aprendi ao longo dos anos, veio através das minhas falhas pessoais. Reconheço que não existe um mundo perfeito e devemos respeitar os ciclos da vida. Hoje em dia tento transmitir a importância de ser resiliente e disposto a vencer quando estou a trabalhar nas minhas empresas, a conversar com empreendedores ou interagindo em projetos onde dou minha consultoria internacional.</p>

                                        <p>A minha reputação, o meu caráter, os meus comportamentos, inspirarão as pessoas mais do que qualquer outra coisa. A única maneira de conseguir o melhor dos outros é dar o melhor de ti mesmo.</p>
                                        <h2 className="title solange" style={{float: "right",marginTop: "-15px"}}>Solange Silva<br /></h2>
                                        <div style={{float: "right", width: "100%",marginTop: "-15px"}}>
                                            <hr style={{width: "150px", borderBottom: "2px solid #eac54b", float: "right", marginBottom: "5px"}}></hr>
                                        </div>
                                        <div style={{float: "right", width: "100%",marginTop: "-5px", marginBottom: "15px"}}>
                                        <hr style={{width: "50px", borderBottom: "2px solid #eac54b", float: "right", marginBottom: "5px"}}></hr>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 order-1 order-lg-2" style={{marginTop: "50px"}}>
                                <img className="w-100" src="/assets/images/people.jpg" alt="SDR Business Academy - Motivar Outros" style={{borderRadius: "5px"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                
                
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;