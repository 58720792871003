import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
    ]
  };
  return (
    <Slider {...settings}>
      
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/1.png" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Passaporte Português</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>Portugal tem um dos passaportes mais poderosos do mundo.<br/>Acesso ao espaço Schengen.<br/>Você pode viajar para 185 países sem visto (como Emirados Árabes Unidos, Japão, Canadá, EUA e muitos mais).<br/>
                                Direito de solicitar a cidadania portuguesa após 5 anos.<br/>Se a cidadania for obtida no futuro, é permitida a dupla nacionalidade.</p>
                                </div>
                                </div>
                        </div>
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/2.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Processo Simplificado</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>Residência europeia em 6 meses.<br/>
                                Não há necessidade de fazer um teste de idioma ou fazer uma entrevista. Residência física mínima de 7 dias por ano.<br/>A sua família com mais de 18 anos pode acompanhá-lo se comprovar dependência financeira (isso inclui filhos maiores de 18 anos e pais).<br/>O seu cônjuge e filhos menores de 18 anos obtêm Autorização de Residência Permanente em Portugal tal como você.</p>
                                </div>
                                </div>
                        </div>
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/3.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>O Clima</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>Portugal é definido como tendo um clima mediterrânico.<br/>
                                Nem muito quente, nem muito frio, durante todo o ano.<br/>
                                Estações bem definidas (verões quentes e invernos frios).</p>
                                </div>
                                </div>
                        </div>
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/4.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Acesso ao Espaço Schengen</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>
                                O visto para Portugal dá-lhe acesso a 29 países Schengen na Europa:<br/> Áustria, Bélgica, República Checa, Dinamarca, Estónia, Finlândia, França, Alemanha, Grécia, Hungria, Islândia, Itália, Letónia, Liechtenstein, Lituânia, Luxemburgo, Malta, Mónaco, Países Baixos, Noruega, Polónia, Portugal, São Marino, Eslováquia, Eslovénia, Espanha, Suécia, Suíça, Vaticano.</p>
                                </div>
                                </div>
                        </div>
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/5.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Benefícios Geográficos</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>
                                Óptima localização para fazer o contacto entre o continente europeu, africano e americano. Vasta área marítima territorial. Longe de zonas com conflitos recorrentes e recentes.</p>
                                </div>
                                </div>
                        </div>
                        
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/6.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Educação</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>
                                A escolaridade obrigatória tem a duração de 12 anos, dos seis aos 18 anos ou até à conclusão do ensino secundário.<br/>A educação pública é gratuita.<br/>O ensino superior público tem propinas que rondam os 1.000 euros por ano.<br/>Algumas das melhores universidades do mundo.</p>
                                </div>
                                </div>
                        </div>
                        
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/7.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Custo de Vida Português</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>
                                Portugal tem um dos custos de vida mais baixos da Europa.<br/>O custo de habitação é inferior à média europeia.<br/>O custo das compras e comer fora é muito razoável fora dos locais turísticos, que pode ser um pouco mais caro.</p>
                                </div>
                                </div>
                        </div>
                        
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/8.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Saúde</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>
                                Segundo a Organização Mundial de Saúde, Portugal tem um dos melhores sistemas de saúde do mundo. É grátis, com taxas mínimas para usar.</p>
                                </div>
                                </div>
                        </div>
                        
                        <div className="item-portugal">
                          <div>
                                <img src="/assets/images/featured/9.jpg" />
                                <div className="it-content">
                                <h4 className="title hub-especializado" style={{fontWeight: '700', fontStyle: 'italic'}}>Impostos</h4>
                                <hr style={{width: "50px", borderBottom: "2px solid #eac54b", marginBottom: "15px"}}></hr>
                                <p style={{color:'white'}}>
                                  Você só será tributado sobre os seus ganhos obtidos em qualquer parte do mundo se residir em Portugal por mais de 183 dias em qualquer ano.</p>
                                </div>
                                </div>
                        </div>
    </Slider>
  );
}