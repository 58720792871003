import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
// import Slider from "react-slick";
// import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck } from "react-icons/fi";
// import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";


// const SlideList = [
//     {
//         textPosition: 'text-right',
//         bgImage: 'bg_image--32',
//         category: '',
//         title: 'Business.',
//         description: 'There are many variations of passages but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     },
//     {
//         textPosition: 'text-left',
//         bgImage: 'bg_image--31',
//         category: '',
//         title: 'Agency.',
//         description: 'There are many variations of passages but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     },
    
// ]

const ServiceListOne = [
    {
        icon: <FiCast />,
        title: 'Crescimento Empresarial'
    },
    {
        icon: <FiLayers />,
        title: 'Potencial de Negócios'
    },
    {
        icon: <FiUsers />,
        title: 'Gestão de Pessoas'
    },
]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="SDR Business Academy" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5"  style={{background: "green"}}>
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/xlRQ3Ptm_4x.jpg" alt="Solange Silva"/>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">

                                    <h3 className="title subtitulo" style={{color: "white"}}>"Um projecto empresarial começa com uma visão. O desafio é torná-la realidade."</h3>
                                        {/* <img className="w-100" src="/assets/images/about/sol-02.svg" style={{width: "250px !important"}} alt="Solange Silva"/> */}
                                        <p>Solange Silva é uma Empreendedora e Business Angel com investimentos a nível global. Se tem uma ideia ou projecto inovador ou um negócio a funcionar que deseja expandir, Solange Silva adopta uma abordagem apaixonada e proativa, para expandir os seus negócios de maneira lucrativa e sustentável.</p>
                                        <p>Solange Silva nasceu em Goiás – Brasil. De família humilde, foi com o sacrifício dos Pais que se formou, pegou no seu espírito empreendedor e foi na busca de crescimento pessoal e da felicidade.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row--35 align-items-center" style={{margin: "45px 0px 0px 0px"}}>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">

                                        <p>Com formação em Marketing, Solange Silva acumulou experiência em grandes empresas multinacionais, trabalhando em diversos sectores e assessorando vários projetos internacionais. </p>
                                        <p>Acostumada a ambientes exigentes e complexos, acredita que os resultados são alcançados com o poder do pensamento, paixão, visão, experiência, conhecimento e sempre trabalhando em equipa.</p>
                                        <p>Optimista por natureza, ela promove um ambiente profissional harmonioso que leva a relacionamentos de longo prazo com os seus parceiros e clientes.</p>
                                        <p>Solange Silva entende que a família e a felicidade pessoal são a chave para o sucesso profissional e tem como objetivo diário encontrar um equilíbrio entre ambos.</p>
                                        <h2 className="title solange" style={{float: "right",marginTop: "-15px"}}>Solange Silva<br /></h2>
                                        <div style={{float: "right", width: "100%",marginTop: "-15px"}}>
                                            <hr style={{width: "150px", borderBottom: "2px solid #eac54b", float: "right", marginBottom: "5px"}}></hr>
                                        </div>
                                        <div style={{float: "right", width: "100%",marginTop: "-5px", marginBottom: "15px"}}>
                                        <hr style={{width: "50px", borderBottom: "2px solid #eac54b", float: "right", marginBottom: "5px"}}></hr>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/OKf5GbeW_4x.jpg" alt="Solange Silva"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                
                
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;