import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
// import Slider from "react-slick";
// import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck } from "react-icons/fi";
// import CounterOne from "../elements/counters/CounterOne";
import BrandTwo from "../elements/BrandTwo";


// const SlideList = [
//     {
//         textPosition: 'text-right',
//         bgImage: 'bg_image--32',
//         category: '',
//         title: 'Business.',
//         description: 'There are many variations of passages but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     },
//     {
//         textPosition: 'text-left',
//         bgImage: 'bg_image--31',
//         category: '',
//         title: 'Agency.',
//         description: 'There are many variations of passages but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     },
    
// ]

const ServiceListOne = [
    {
        icon: <FiCast />,
        title: 'Crescimento Empresarial'
    },
    {
        icon: <FiLayers />,
        title: 'Potencial de Negócios'
    },
    {
        icon: <FiUsers />,
        title: 'Gestão de Pessoas'
    },
]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="SDR Business Academy" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Brand Area  */}
                <div className="rn-brand-area brand-separation bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="title" style={{marginBottom: "40px", textAlign: "center"}}>Nosso Grupo Empresarial</h2>
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                
                
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;