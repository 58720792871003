import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                <li>
                        <img src="/assets/images/brand/logo (1).jpg" alt="Best Auction World"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (1).png" alt="Selos do Mundo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (2).jpg" alt="The Nanny World"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (3).jpg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (4).jpg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (5).jpg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (2).png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (3).png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/logo (4).png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/greenmobility-400.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/renewable-400.png" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;