import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Sua mensagem foi enviada com sucesso. Responderemos brevemente.</p>
    )
}
function ContactForm({props}) {
    const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_0u003mj', 
            'template_jxpqxle',
            e.target,
            'UwhD_lFmOcNk6veyw')
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input 
                type="text"
                name="from_name"
                placeholder="Nome *"
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="email"
                name="email"
                placeholder="E-mail *"
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Telemóvel *"
                required
                />
            </div>

            <div className="rn-form-group" style={{marginBottom: '15px'}}>
                <select 
                name="subject"
                placeholder="Assunto"
                required
                >
                    <option value="seleccione" disabled selected>Seleccione o tópico desejado *</option>
                    <optgroup label="Inscrição">
                        <option value="Hub">Hub - Alojamento de empresas </option>
                        <option value="Lab">Lab - Incubação de empresas</option>
                    </optgroup>
                    <optgroup label="Residência e Golden Visa">
                        <option value="Golden Visa">Golden Visa </option>
                        <option value="Golden Visa">Títulos de Residência </option>
                    </optgroup>
                    <optgroup label="Geral">
                        <option value="Geral">Contacto geral / Outros </option>
                    </optgroup>
                </select>
            </div>
            
            <div className="rn-form-group">
                <textarea 
                name="message"
                placeholder="Mensagem *"
                required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe" style={{background: 'linear-gradient(315deg, rgb(255, 208, 130), rgb(196, 136, 64))', color: 'white'}}>Enviar contacto</button>
            </div> 

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
