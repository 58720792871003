import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1 contato-golden-visa">
                            <div className="section-title text-left mb--30 pt--30">
                                <h2 className="title" style={{color: 'white', fontSize: '36px'}}>Entre em contacto</h2>
                                <p className="description" style={{fontStyle: 'italic'}}>Vamos conversar. Conte-nos abaixo sobre seu projecto.</p>
                                <hr style={{width: '80px', borderBottom: '2px solid goldenrod'}}/>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 contacto-imagem">
                            <div className="thumbnail mb_md--30 mb_sm--30" data-black-overlay="3">
                                <img src="/assets/images/contactos-sdr.jpg" alt="São Domingos de Rana Business Academy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;