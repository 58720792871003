import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
// import Slider from "react-slick";
// import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck } from "react-icons/fi";
import CookieConsent from "react-cookie-consent";




class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="SDR Business Academy" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark-grupo" color="color-black"  />
                {/* End Header Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation ptb--150 pt--90 privacidade-page" style={{background: 'white', paddingTop: '100px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div class="page-content">
					<h2>Politica de Privacidade e Tratamento de Dados</h2>
<hr/>
<h5><i>A privacidade e a segurança dos dados que nos confia, são a nossa prioridade.</i></h5>
<p>A Força & Grandeza - São Domingos de Rana - Business Academy, Lda. agradece a sua visita às nossas páginas web e o seu interesse nos nossos serviços / produtos. A protecção dos seus dados pessoais é um objectivo muito importante para nós. Nas informações que se seguem, relativas à protecção de dados, esclarecemos a forma como recolhemos os seus dados pessoais, como os tratamos, para que fins e com que fundamento são tratados bem como quais os direitos que lhe assistem.</p>
<ul>
<li>A Força & Grandeza - São Domingos de Rana - Business Academy, Lda., melhor identificada infra é a entidade responsável pelo tratamento dos dados pessoais fornecidos pelos utilizadores do site, a informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel, morada, data de nascimento e/ou outros.</li>
<li>Ao fornecer os dados, o utilizador / cliente aceita que estes possam ser recolhidos e tratados para efeitos de publicidade, marketing (incluindo através de qualquer meio de marketing directo e comunicações publicitárias não solicitadas, desde que o utilizador / cliente a tal não se oponha), ofertas comerciais e/ou promocionais.</li>
<li>O utilizador / cliente aceita que os dados por ele fornecidos e/ou a ele respeitantes, possam ser transmitidos e utilizados para quaisquer dos fins referidos no número anterior a empresas que se encontrem em relação de grupo com a Força & Grandeza - São Domingos de Rana - Business Academy, Lda., entendendo-se como tal: empresas participadas pela Força & Grandeza - São Domingos de Rana - Business Academy, Lda., empresas em que participem empresas por ela participada, empresas que sejam detentoras de parte do capital social da Força & Grandeza - São Domingos de Rana - Business Academy, Lda., empresas que sejam participadas por empresas detentoras de parte do capital social da Força & Grandeza - São Domingos de Rana - Business Academy, Lda., ou, empresas em que participem os detentores de participação social da Força & Grandeza - São Domingos de Rana - Business Academy, Lda.</li>
<li>A Força & Grandeza - São Domingos de Rana - Business Academy, Lda. possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.</li>
<li>Os dados fornecidos podem ainda ser transmitidos ao transportador / serviço postal, exclusivamente para o efeito de entrega e processamento das encomendas efetuadas e prestação de informações sobre tal entrega e processamento.</li>
<li>A Força & Grandeza - São Domingos de Rana - Business Academy, Lda. implementará e manterá medidas técnicas e organizativas adequadas a proteger os dados contra a destruição acidental ou ilícita, a sua difusão ou acesso não autorizados, a sua adulteração ou a sua perda, procurando, assim, assegurar a integridade e confidencialidade dos dados pessoais. Tais medidas incluem mecanismos de cópias de segurança, permissões e autorizações de acesso e tratamento reservados, com vista a impedir o acesso por parte de terceiros não autorizados (outros que não o utilizador / cliente titular dos dados, em relação aos dados que lhe digam diretamente respeito, a Força & Grandeza - São Domingos de Rana - Business Academy, Lda, as entidades que actuem por sua conta no tratamento dos dados, o seu pessoal devidamente habilitado e as entidades referidas no número 3).</li>
<li>A Força & Grandeza - São Domingos de Rana - Business Academy, Lda. obriga-se por si, pelas entidades por ela contratadas para o tratamento dos dados, pelos seus trabalhadores e colaboradores e pelas entidades referidas no n.º 3) a guardar rigoroso sigilo sobre os dados e informações confidenciais de que venha a ter conhecimento por via do ‘Site’, só estando autorizada a utilizá-los para os fins constantes das condições gerais, para o exercício de qualquer direito no âmbito de contratos celebrados com o utilizador / cliente, ou na sequência de uma ordem legítima de qualquer autoridade judicial ou administrativa.</li>
<li>Sem prejuízo do disposto nos números anteriores, a Força & Grandeza - São Domingos de Rana - Business Academy, Lda. adverte e o cliente aceita, que a recolha, difusão e tratamento de dados em redes abertas comporta riscos, podendo os dados ser vistos e utilizados por terceiros não autorizados, mesmo contra a vontade e independentemente do contributo ou conhecimento da Força & Grandeza - São Domingos de Rana - Business Academy, Lda. e do utilizador / cliente, pelo que a Força & Grandeza - São Domingos de Rana - Business Academy, Lda. ou qualquer entidade autorizada a proceder ao tratamento dos dados, não poderá ser responsabilizada por tais acessos não autorizados. Por outro lado, ao usar o ‘Site’, seja na óptica de Utilizador do seja na óptica de visualizador de conteúdos, o utilizador / cliente pode ser exposto a conteúdos que, contrária e independentemente da vontade da Força & Grandeza - São Domingos de Rana - Business Academy, Lda., violem os termos e condições gerais do site ou normas legais aplicáveis, não podendo a Força & Grandeza - São Domingos de Rana - Business Academy, Lda. ou qualquer entidade autorizada a proceder ao tratamento dos dados ser responsabilizada por tais actos.</li>
<li>A Força & Grandeza - São Domingos de Rana - Business Academy, Lda. e as entidades referidas no número 3 supra, poderão conservar e manter os dados por todo o tempo necessário a assegurar o integral cumprimento dos contratos que venham a ser celebrados com o utilizador / cliente o que compreende o direito de conservação e utilização dos dados, para os referidos fins, mesmo depois dos referidos contratos ou acordos deixarem de produzir efeitos, por qualquer causa, e depois do período do prazo de exercício do direito de livre resolução.</li>
<li>Para os devidos e legais efeitos, segue a identificação completa e contactos da entidade responsável pelo tratamento de dados pessoais:
<ul>
<li>Denominação: Força & Grandeza - São Domingos de Rana - Business Academy, Lda.</li>
<li>Número de Identificação de Pessoa Colectiva: 516636332</li>
<li>Sede e endereço postal:
<ul>
<li>Estrada Nacional 249-4, Km 6<br/>
Multi Business Center<br/>
Escritório D-11<br/>
2785-035 São Domingos de Rana</li>
</ul>
</li>
<li>Endereço electrónico: <a href="mailto:geral@sdr-businessacademy.com" target="_blank">geral@sdr-businessacademy.com</a></li>
</ul>
</li>
<li>Recrutamento:
<ul>
<li>Ao preencher o formulário de inscrição para processos de recrutamento formaliza a sua candidatura, consentindo e autorizando, que os dados recolhidos pela Força & Grandeza - São Domingos de Rana - Business Academy, Lda. sejam processados, para efeitos de processos de recrutamento, que venham a ser iniciados durante os próximos 12 (doze) meses. Findo esse período, esta candidatura deixará de constar da listagem de candidaturas activas, pelo que, para novos processos de recrutamento deverá voltar a candidatar-se. Não são aceites candidaturas de menores.</li>
</ul>
</li>
</ul>
<p>Todos os dados recolhidos serão tratados em concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro de 1998 (Lei n.º 67/98) na sua mais recente versão, para a qual remetemos.</p>
				</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}     

                
                
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <CookieConsent buttonText="Aceito">Este site utiliza cookies para permitir uma melhor experiência por parte do utilizador. Ao navegar no site estará a consentir a sua utilização. <a href="/politicas-privacidade">Políticas de Privacidade</a></CookieConsent>
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;