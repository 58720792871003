import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
// import Slider from "react-slick";
// import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck } from "react-icons/fi";
import BrandTwo from "../elements/BrandTwo";




class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="SDR Business Academy" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark-grupo" color="color-black"  />
                {/* End Header Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation ptb--150 pt--150" style={{background: 'white', paddingTop: '100px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <div style={{textAlign:'-webkit-center', marginBottom: '40px'}}>
                                        <h3 style={{lineHeight: 1.3, textAlign:"center", color:'#4a4a4a',fontSize:'36px', marginBottom: '07px'}}>O nosso grupo empresarial</h3>
                                        {/* <p style={{maxWidth:"650px"}}>Aberto a pequenas, médias e grandes empresas que pretendam tornar-se mais competitivas, mas também a empreendedores que procuram desenvolver a sua ideia de negócio na nossa incubadora.</p> */}
                                        <hr style={{margin:"10px 0px",borderBottom:"2px solid #4a4a4a", width:"80%", maxWidth:'200px'}} /> 
                                    </div>
                                    <BrandTwo />
                                    
                                </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}     

                
                
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;