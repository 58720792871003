import React, { Component } from "react";
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import Slick from "../blocks/Slick04";
import ContactForm from "../elements/contact/ContactForm2";
import Accordion1 from "../elements/Accordion02";


class PortugalDetails extends Component{
    
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        document.body.classList.remove('portugal-class')
        document.body.classList.remove('hub-class')
        document.body.classList.remove('golden-class')
        document.body.classList.add('lab-class')
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Helmet  */}
                <Helmet pageTitle='Títulos de Residência em Portugal' />
                {/* End Helmet  */}

                <Header headerPosition="header--static logoresize logo-hub" logo="all-dark" color="color-black" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--150 pb--190 bg_image bg-image-hub text-right" style={{backgroundImage: 'url(/assets/images/service/back-portugal-residencia.jpg)'}} data-black-overlay="7" >
                    <div className="container">
                        <h2 className="title solange" style={{lineHeight:'1.2',paddingRight: '10px'}}>Títulos de Residência em Portugal</h2>
                        <h4 style={{lineHeight:1.3, color: "white", fontWeight: '300', fontSize: '30px', paddingLeft: '10px', fontStyle: 'italic'}}>Obtenha a residência permanente em um dos melhores países do mundo.</h4>
                    </div>
                </div>
                <div className="container">
                    <div className="row fadein-class acoes-rapidas-hub">
                            <div className="col-lg-3 col-sm-12 offset-lg-1" style={{background: 'linear-gradient(45deg, #ffd082, #c48840)'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Obter Residência</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: '#9ac23a'}}>
                                <a href="#mais-informacoes">
                                    <h4 style={{color: 'white'}}><i className="fa fa-plus-square" aria-hidden="true"></i> Mais informações</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: 'linear-gradient(315deg, #ffd082, #c48840)'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-headphones" aria-hidden="true"></i> Nossos contactos</h4>
                                </a>
                            </div>
                        </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start custos Area */}
                <div className="service-area ptb--30 bg_color--1 pt--100 pb--20 servicos-quemsomos" style={{backgroundColor: 'transparent'}} id="mais-informacoes">
                    <div className="container">
                        <div className="row service-one-wrapper services-investimento" style={{alignContent: 'center',alignItems: 'center', justifyContent: 'center'}}>
                            <div className="col-12" style={{marginBottom: '10px'}}>
                                <h2 className="title solange" style={{marginTop: '15px', lineHeight:'0.9', padding: '15px'}}>O que é um título de residência?</h2>
                                <p style={{fontWeight: '300',textAlign: 'justify'}}>O título de residência (TR) é o documento emitido, de acordo com as regras e o modelo uniforme em vigor na União Europeia, ao nacional de estado terceiro autorizado a residir em território nacional. Este título substitui, para todos os efeitos legais, o documento de identificação do cidadão estrangeiro, sendo portanto o único documento apto a comprovar a qualidade de residente legal em Portugal.</p>
                                <h4 className="title hub-especializado" style={{fontWeight: '400', marginBottom: '40px', fontStyle: 'italic', marginTop: '30px', marginBottom: '30px'}}>É concedida autorização de residência ao cidadão estrangeiro:</h4>
                                <Slick/>
                            </div>

                        </div>

                        <div className="row service-one-wrapper">
                            <div className="col-12">
                                <h4 className="title hub-especializado" style={{fontWeight: '400', marginBottom: '40px', fontStyle: 'italic', marginTop: '10px', marginBottom: '30px'}}>Para além dos acima referidos, existem ainda:</h4>
                                <ul style={{paddingLeft: '10px'}}>
                                    <li><p>Autorização de residência para as vítimas de tráfico de pessoas ou de ação de auxílio à imigração ilegal.</p></li>
                                    <li><p>Autorização de residência para titulares do estatuto de residente de longa duração em outro Estado membro da União Europeia.</p></li>
                                    <li><p>Autorização de residência «cartão azul EU» para o exercício, em território nacional, de uma atividade altamente qualificada.</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End custos Area */}

                {/* Start porque portugal Area  */}
                <div className="rn-about-area ptb--20 bg_color--5 porque-portugal" style={{background: "transparent"}}>
                    <div className="container">
                        <div className="row" style={{marginBottom: '30px'}}>
                                <h3 className="title hub-especializado" style={{marginTop: '15px', lineHeight:'1.3', color: '#daa520'}}>Que tipos de autorização/títulos de residência existem em Portugal?</h3>
                                <div className="tabela-residencia text-center ptb--20">
                                    <div className="col-12 col-lg-6" style={{float: 'left'}}><h5>Autorização de residência temporária</h5>
                                    <p>A autorização de residência temporária (ART) é um título de residência que permite aos cidadãos estrangeiros residir em Portugal durante um certo período de tempo e que tem as seguintes caraterísticas:
                                                <ul>
                                                    <li>Em regra, é válida pelo período de um ano, a partir da data da emissão do respetivo título</li>
                                                    <li>É renovável por períodos sucessivos de dois anos</li>
                                                    <li>O título de residência deve ser renovado sempre que se verifique alteração dos elementos de identificação nele registado</li>
                                                </ul>
                                            </p>
                                    </div>
                                    <div className="col-12 col-lg-6" style={{float: 'right'}}><h5>Autorização de residência permanente</h5>
                                    <p>A autorização de residência permanente (ARP) é um título de residência que permite aos cidadãos estrangeiros residir em Portugal por tempo indeterminado e que tem as seguintes caraterísticas:
                                        <ul>
                                            <li>Não tem limite de validade.</li>
                                            <li>O título de residência deve, porém, ser renovado de cinco em cinco anos ou sempre que tal se justifique, isto é, quando se verifiquem alterações nos elementos de identificação pessoal.</li>
                                        </ul>
                                            </p>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="container">
                        
                    </div>
                </div>
                {/* End About Area  */}
                
                {/* Start Single Area */}
                <div className="container">
                    <div className="row sercice-details-content align-items-start pt--60 pb--80 contact-form--1">
                                        <div className="col-lg-3 order-1 order-lg-1" style={{height: 'auto', marginBottom: 'auto', marginTop: 'auto'}}>
                                            <div className="section-title text-left">
                                                <h3 className="title hub-especializado" style={{marginTop: '15px', lineHeight:'1.3', color: '#daa520', fontSize: '24px'}}>Tipos de Títulos de Residência em Portugal:</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 order-2 order-lg-2 residenc--">
                                            <div className="accordion-portugal residencia" style={{marginTop: '20px', marginBottom: '30px'}}>
                                                            <Accordion1/>
                                            </div>
                                            <a href="/contactos" className="custos-button-resid" style={{color: 'white'}}><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Pedir uma avaliação gratuita sobre os títulos de residência</a>
                                        </div>
                                        </div>
                                        </div>
                                        {/* End Single Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default PortugalDetails;