/* eslint-disable */
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";


class HeaderFive extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onClick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { logo, color , headerPosition} = this.props;
        var grupo = '';
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="São Domingos de Rana Business Academy" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="São Domingos de Rana Business Academy" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="São Domingos de Rana Business Academy" />;
        }else if(logo === 'all-dark'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="São Domingos de Rana Business Academy" />;
        }else if(logo === 'all-dark-grupo'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="São Domingos de Rana Business Academy" />;
            grupo = 'grupo';
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="São Domingos de Rana Business Academy" />;
        }else if(logo === 'logo-hub'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-HUB.svg" alt="São Domingos de Rana Business Academy" />;
        }else if(logo === 'logo-lab'){
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-LAB.svg" alt="São Domingos de Rana Business Academy" />;
        }else{
            logoUrl = <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="São Domingos de Rana Business Academy" />;
        }
        
        return(
            <header className={`header-area header-site formobile-menu ${headerPosition} ${color} ${grupo}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4 col-8">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            {logoUrl}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 col-4">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu">
                                            
                                            <li><Link to="./">Home</Link></li>
                                            <li><Link to="/business-angel" >Business Angel</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/business-angel" >Business Angel</Link></li>
                                                    <li><Link to="/internacional" >Consultora Internacional</Link></li>
                                                    <li><Link to="/motivar" >Motivar Outros</Link></li>
                                                    <li><Link to="/nosso-grupo-empresarial">Nosso Grupo Empresarial</Link></li>
                                                </ul>
                                            </li>
                                            {/* LINKS MOBILE */}
                                            <li className="display-mobile"><Link to="/internacional" >Consultora Internacional</Link></li>
                                            <li className="display-mobile"><Link to="/motivar" >Motivar Outros</Link></li>
                                            <li className="display-mobile"><Link to="/nosso-grupo-empresarial">Nosso Grupo Empresarial</Link></li>

                                            {/* END LINKS MOBILE */}

                                            <li><Link to="/hub" >Hub</Link></li>
                                            <li><Link to="/lab" >Lab</Link></li>
                                            <li><Link to="/golden-visa-portugal" >Golden Visa</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/golden-visa-portugal" >Golden Visa</Link></li>
                                                    <li><Link to="/custos-e-vantagens-portugal" >Investir em Portugal - Custos e Vantagens</Link></li>
                                                    <li><Link to="/titulos-de-residencia" >Títulos de Residência</Link></li>
                                                </ul>
                                            </li>
                                            {/* LINKS MOBILE */}
                                            <li className="display-mobile"><Link to="/custos-e-vantagens-portugal" >Investir em Portugal - Custos e Vantagens</Link></li>
                                            <li className="display-mobile"><Link to="/titulos-de-residencia" >Títulos de Residência</Link></li>

                                            {/* END LINKS MOBILE */}

                                            <li><Link to="/contactos" >Contacte-nos</Link></li>
                                            <div style={{paddingRight: '20px'}}>
                                                <a href="#" onClick={() => { doGTranslate('pt|pt'); return false;}} title="Portuguese" className="gflag nturl" style={{backgroundPosition:"-300px -200px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Portuguese" /></a>
                                                <a href="#" onClick={() => { doGTranslate('pt|en'); return false;}} title="English" className="gflag nturl" style={{backgroundPosition:"-0px -0px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="English" /></a>
                                                <a href="#" onClick={() => { doGTranslate('pt|es'); return false;}} title="Spanish" className="gflag nturl" style={{backgroundPosition:"-600px -200px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Spanish" /></a>
                                                {/* <a href="#" onClick={() => { doGTranslate('pt|zh-CN'); return false;}} title="Chinese (Simplified)" className="gflag nturl" style={{backgroundPosition:"-300px -0px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Chinese (Simplified)" /></a>
                                                <a href="#" onClick={() => { doGTranslate('pt|fr'); return false;}} title="French" className="gflag nturl" style={{backgroundPosition:"-200px -100px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="French" /></a>
                                                <a href="#" onClick={() => { doGTranslate('pt|de'); return false;}} title="German" className="gflag nturl" style={{backgroundPosition:"-300px -100px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="German" /></a>
                                                <a href="#" onClick={() => { doGTranslate('pt|ru'); return false;}} title="Russian" className="gflag nturl" style={{backgroundPosition:"-500px -200px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Russian" /></a>
                                                
                                                <a href="#" onClick={() => { doGTranslate('pt|tr'); return false;}} title="Turkish" className="gflag nturl" style={{backgroundPosition:"-100px -500px"}}><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Turkish" /></a> */}
                                            </div>
                                        </ul>
                                    </nav>
                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
        )
        
    }
    
}

export default HeaderFive;