// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import CorporateBusiness from './home/CorporateBusiness';


// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import BusinessAngel from "./elements/BusinessAngel";
import Sinergias from "./elements/Sinergias";
import Motivar from "./elements/Motivar";
import Internacional from "./elements/Internacional";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Hub from "./elements/Hub";
import Lab from "./elements/Lab";
import Privacidade from "./elements/Privacidade";
import CentroArbitragem from "./elements/CentroArbitragem";
import GrupoEmpresarial from "./elements/GrupoEmpresarial";
import GoldenVisa from "./elements/GoldenVisa";
import Portugal from "./elements/Portugal";
import Residencia from "./elements/Residencia";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";


// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// analytics
const TRACKING_ID = "G-D0CD2W3QHV";
ReactGA.initialize(TRACKING_ID);

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={CorporateBusiness}/>

                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contactos`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/business-angel`} component={BusinessAngel}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/internacional`} component={Internacional}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/Sinergias`} component={Sinergias}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/motivar`} component={Motivar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/hub`} component={Hub}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/lab`} component={Lab}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/politicas-privacidade`} component={Privacidade}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/centro-arbitragem`} component={CentroArbitragem}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/nosso-grupo-empresarial`} component={GrupoEmpresarial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/golden-visa-portugal`} component={GoldenVisa}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/custos-e-vantagens-portugal`} component={Portugal}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/titulos-de-residencia`} component={Residencia}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>

                        {/* Blocks Elements  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>


                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
