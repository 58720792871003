import React, { Component } from "react";
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Slick01 from "../blocks/Slick";



class HubDetails extends Component{
    
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        document.body.classList.remove('lab-class')
        document.body.classList.remove('golden-class')
        document.body.classList.add('hub-class')
        
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Helmet  */}
                <Helmet pageTitle='Hub em Portugal' />
                {/* End Helmet  */}

                <Header headerPosition="header--static logoresize logo-hub" logo="logo-hub" color="color-black" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg-image-hub " style={{backgroundImage: 'url(/assets/images/bg/bg03.jpg)'}} data-black-overlay="5" >
                    <div className="container">
                        
                    </div>
                </div>
                <div className="container">
                    <div className="row fadein-class acoes-rapidas-hub">
                            <div className="col-lg-3 col-sm-12 offset-lg-1" style={{background: '#5cbaaa'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Pedido de inscrição</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: '#9ac23a'}}>
                                <a href="#mais-informacoes">
                                    <h4 style={{color: 'white'}}><i className="fa fa-plus-square" aria-hidden="true"></i> Mais informações</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: '#80c6e0'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-headphones" aria-hidden="true"></i> Nossos contactos</h4>
                                </a>
                            </div>
                        </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details details-hub-2 ptb--40 fadein-class" id="mais-informacoes">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            
                                            <div className="col-lg-8 col-8 col-12">
                                                <div className="details">
                                                    <p style={{fontSize: '14px', fontStyle: 'italic'}}>Tempo aproximado de leitura: 1 minuto, 50 segundos.</p>
                                                <h4 className="title hub-especializado" style={{marginTop: '15px'}}>HUB Especializado em Portugal</h4>
                                                
                                                    <p>O sentido original de <strong>“Hub”</strong> nasce do nome dado a uma peça que concentra conexões de uma rede, permitindo o trânsito de informações entre diferentes elos.<br/><br/>
                                                    No ambiente de negócios a estratégia de Hubs tem o propósito de conectar diferentes necessidades e oportunidades em um ponto de conexão que acelere a produção de novas soluções ou negócios.<br/>
                                                    <strong>São os Hubs de Inovação que tornam tangíveis as estratégias de inovação aberta.</strong><br/><br/>
                                                    Os Hubs são espaços – físicos e/ou virtuais – nos quais empresas e start-ups podem trabalhar e ter acesso a contactos, investidores, mentores e fornecedores. Nesses ambientes férteis, os projetos têm maior facilidade para crescer, tanto pelo vasto networking, quanto pelo suporte para a aceleração.<br/><br/>
                                                    Nesse modelo de Hub, os participantes conectados à estrutura recebem apoios que fortalecem sua performance, optimizam os custos e agilizam a construção e implantação de projetos inovadores.</p>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-4 col-12">
                                                <div className="beneficios-hub">
                                                    <h4>Benefícios:</h4>
                                                    <ul style={{listStyle: 'none', listStylePosition: 'inside'}}>
                                                        <li> Estacionamento exclusivo gratuito ✓</li>
                                                        <li> Escritório virtual para empresas ✓</li>
                                                        <li> Acesso a sala de reuniões ✓</li>
                                                        <li> Acesso a programas de Mentoria ✓</li>
                                                        <li> Acesso a programas de Incubação ✓</li>
                                                        <li> Acesso a ações de Formação Profissional ✓</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <div className="row empresas-hub-row">
                                            <div className="col-lg-12" style={{paddingTop:'40px',paddingBottom:'40px'}}>
                                            
                                                <h4 style={{marginBottom: '0', marginTop: '25px'}}>Empresas alojadas no nosso hub:</h4>
                                                <hr style={{borderBottom: '2px solid #d4af37', maxWidth: '300px', marginBottom: '30px'}} />
                                                <Slick01/>
                                                
                                            </div>
                                        </div>
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details details-hub mt_md--30 mt_sm--30">
                                                    
                                                    <h4 className="title hub-especializado">As metodologias</h4>
                                                    <ul className="liststyle">
                                                    <li><h5>Processos de comunicação:</h5></li>
                                                    <p>A eliminação de algumas burocracias e barreiras permite que as informações sejam transmitidas de forma <strong>rápida e eficaz</strong>. Isso torna possível interligar processos internos e externos que promovem economia de tempo e dinheiro.</p>

                                                    <li><h5>Networking:</h5></li>
                                                    <p>Um ambiente de <strong>proximidade entre empresas inovadoras</strong> aumenta ainda mais a chance de novas ideias e soluções cada vez melhores e mais disruptivas. Além disso, a possibilidade de start-ups crescerem individualmente e do surgimento de novas parcerias e projetos é muito mais alta nesse ambiente.</p>

                                                    <li><h5>Contacto com especialistas e mentores:</h5></li>
                                                    <p>Parte da estrutura de um hub é composta por ferramentas e serviços à disposição das start-ups, como mentores e <strong>especialistas das mais diversas áreas</strong> – sejam elas académicas ou corporativas, que podem colaborar com os processos internos da start-up e optimizá-los.</p>

                                                    <li><h5>Qualidade e economia:</h5></li>
                                                    <p>Por ser um espaço concentrador de agentes especializados nas mais diversas soluções e com maior flexibilidade de adaptação às diferentes necessidades – que ainda contam com as facilidades de <strong>redução de custos</strong>, os hubs ofertam soluções assertivas, personalizadas, rápidas e menos custosas que as soluções externas.</p>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/sala-reunioes.jpg" style={{objectFit: 'cover', maxHeight:'700px', height:'50vw'}} alt="Hub especializado de negócios em Portugal - São Domingos de Rana"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default HubDetails;