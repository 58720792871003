import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class Accordion01 extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'a'} >
                <AccordionItem uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>D1</span> – Visto para trabalhar em Portugal <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel >
                        <p>
                        Este visto de residência para Portugal é para quem venha realizar actividade profissional subordinada. Por isso, será importante comprovar que possui promessa ou contrato de trabalho para actuar no país.<br/>
                        Este visto refere-se às actividades profissionais subordinadas. Vale mencionar que existem outros tipos de vistos relacionados ao exercício profissional em Portugal.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>D2</span> – Visto para Empreendedor como independente ou para abertura de empresa <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Este é o visto próprio para quem deseja realizar actividade profissional em Portugal, mas de forma autônoma, por período superior a um ano. É também o visto indicado para quem deseja abrir uma empresa no país.<br/>
                        Para quem pretende empreender, é necessário avaliar as possibilidades do mercado português, já que é preciso demonstrar que a empresa tem relevância económica e social para o país.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>D3</span> – Visto para Investigação ou Actividade Altamente Qualificada <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Este visto para Portugal é similar ao temporário anteriormente citado, do mesmo tipo. Mas, é aplicável para pesquisadores ou docentes que desejem desenvolver sua actividade por mais de um ano, em Portugal.<br/>
                        Bem objectivamente, a Lei de Estrangeiros de Portugal define que o Visto D3 Portugal para profissional altamente qualificado, como actividade para ser exercida, exige competências técnicas especializadas, de carácter excepcional ou uma qualificação adequada para o respectivo exercício.<br/>
                        Vale mencionar que quando for pedir o visto D3 Portugal, será realizada uma análise bem detalhada da sua situação, para ver se você poderá ser classificado como profissional altamente qualificado, segundo os critérios legais de Portugal e da Comissão Europeia.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>D4</span> – Visto de Estudo, Estágio e Voluntariado <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Da mesma forma, este visto para Portugal é voltado para quem realizará alguma formação educacional (licenciatura, mestrado, doutoramento), um estágio ou trabalho voluntário por período superior a um ano. <br/>
                        Esta é uma grande oportunidade para os cidadãos que pretendem estudar em Portugal com o objectivo de melhorar o currículo, e consequentemente, aumentar as chances de conseguir um emprego ideal.<br/>
                        Além da documentação básica exigida para o visto D4, você deve apresentar os comprovantes da actividade que vai fazer. Por exemplo, se você for aceite numa universidade portuguesa, deve enviar a carta de aceitação da instituição.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>D5</span> – Visto de Estudo/Mobilidade (para quem já está na Europa) <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Este visto de longa duração é pensado para quem não é nacional de um país da União Europeia, mas que já esteja na Europa a estudar. Assim, este visto permitirá a mobilidade e que o interessado realize também formação em Portugal.<br/>
                        Este visto é destinado para estudantes que fazem algum curso de nível superior na Europa há menos de 2 anos e queiram transferir seus estudos para Portugal ou dar continuidade em algum programa similar no país.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>D6</span> – Visto para Reagrupamento Familiar <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>O visto para reagrupamento é aplicável para os familiares de titulares de visto ou de autorização de residência em Portugal. O objectivo é possibilitar que a família seja reagrupada no país.<br/>
                        O reagrupamento familiar funciona da seguinte forma: uma pessoa que obtém uma autorização de residência para morar em Portugal (seja por estudo, trabalho, investimento etc.) tem o direito a reagrupar os familiares próximos ou que estão sob sua responsabilidade.<br/>
                        Depois de fazer o pedido e ser deferido, os membros da família também recebem autorização para viver no país e podem até trabalhar, se assim desejarem.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>D7</span> – Visto para Aposentados ou Titulares de Rendimentos <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Este visto de residência para Portugal é muito solicitado pelos cidadãos séniores. É pensado para quem já é aposentado ou possui rendimentos suficientes para se manter sem trabalhar em Portugal. Essa renda pode ser proveniente de direitos autorais, alugueres recebidos, lucros e dividendos de empresas, investimento financeiro, etc.<br/>
                        Foi criado para incentivar e atrair para Portugal pessoas que já tenham um rendimento mensal garantido. Dessa forma, quem tira o visto D7 para vir morar no país, ajuda na movimentação da economia local e na geração de renda ou de empregos.<br/>
                        O visto abrange diversas possibilidades, desde que seja comprovado o preenchimento dos requisitos exigidos, incluindo o valor mínimo anual, com apresentação da documentação adequada.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>Tech Visa</span> <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Visto para quem deseja empreender em Portugal. Especificamente em alguma área de especial interesse e inovação ou que seja trabalhador do mercado da tecnologia. <br/>
                        O Tech Visa está em vigor desde 2019 e seu objetivo é tornar mais eficaz e eficiente a concessão de visto e a atribuição de autorização de residência para imigrantes altamente qualificados.<br/>
                        O programa funciona de uma maneira diferente dos demais vistos para exercício profissional em Portugal. Como é um visto pensado exclusivamente para a área tecnológica, primeiro há uma certificação das empresas que actuam neste mercado.<br/>
                        Então, o programa inicia-se com um registo prévio das empresas tecnológicas e inovadoras, que podem atrair profissionais qualificados. A partir daí os trabalhadores contratados poderão solicitar o visto de tecnologia para Portugal. Ao chegarem no país, receberão uma autorização de residência vinculada ao Programa Tech Visa.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>StartUp Visa</span> <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>O Startup Visa Portugal é uma maneira de incentivar o empreendedorismo no país. Foi criado em 2018 e oferece a permissão de residência no país para cidadãos de fora da União Europeia, que queiram abrir uma empresa inovadora em Portugal. </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <span>Golden Visa</span> para grandes investidores <i class="fa fa-plus" aria-hidden="true"></i>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <a href="/golden-visa-portugal">Clique aqui para saber mais</a>
                        <p>Autorização de Residência para quem realize investimentos em Portugal, como investimento imobiliário ou mesmo financeiro, abarcando diversas modalidades. <br/>
                        O Golden Visa Portugal foi criado em 2012 com o objetivo de captar recursos e investidores para o país. Isso ocorre mediante a concessão de uma Autorização de Residência especial para estrangeiros de fora da União Europeia, que tenham investido em Portugal. <br/>
                        Há diversas modalidades de investimento que habilitam o seu titular a requerer o Golden Visa, tais como: investimento imobiliário, transferência de capitais, criação de postos de trabalho e participação em fundos de investimento ou de capitais de risco.<br/>
                        É importante lembrar que, o governo de Portugal definiu novas regras do Visto Gold que serão aplicadas a partir de 2022. As principais mudanças dizem respeito ao aumento dos valores mínimos necessários aos investimentos em geral para concessão deste tipo de visto.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                

            </Accordion>
        )
    }
}
export default Accordion01;





