import React, { Component } from "react";
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";



class LabDetails extends Component{
    
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        document.body.classList.remove('hub-class')
        document.body.classList.remove('golden-class')
        document.body.classList.remove('portugal-class')
        document.body.classList.add('lab-class')
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            
            <React.Fragment>
                
                {/* Start Helmet  */}
                <Helmet pageTitle='Lab em Portugal' />
                {/* End Helmet  */}

                <Header headerPosition="header--static logoresize logo-hub" logo="logo-lab" color="color-black" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg-image-hub " style={{backgroundImage: 'url(/assets/images/bg/bg02.jpg)'}} data-black-overlay="5" >
                    <div className="container">
                        
                    </div>
                </div>
                <div className="container">
                    <div className="row fadein-class acoes-rapidas-hub">
                            <div className="col-lg-3 col-sm-12 offset-lg-1" style={{background: '#5cbaaa'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Pedido de inscrição</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: '#9ac23a'}}>
                                <a href="#mais-informacoes">
                                    <h4 style={{color: 'white'}}><i className="fa fa-plus-square" aria-hidden="true"></i> Mais informações</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: '#80c6e0'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-headphones" aria-hidden="true"></i> Nossos contactos</h4>
                                </a>
                            </div>
                        </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details details-hub-2 ptb--40 fadein-class" id="mais-informacoes">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            
                                            <div className="col-lg-12 col-12">
                                                <div className="details">
                                                    <p style={{fontSize: '14px', fontStyle: 'italic'}}>Tempo aproximado de leitura: 1 minuto, 42 segundos.</p>
                                                <h4 className="title" style={{marginTop: '15px'}}>LAB - Laboratório de negócios em Portugal</h4>
                                                
                                                    <p>Um <strong>laboratório de negócios</strong> é um espaço empresarial projetado para criar condições favoráveis para que os projetos aconteçam e as ideias saiam do papel. Ou seja, é um ambiente criativo e colaborativo em que novos conhecimentos podem ser facilmente compartilhados e ideias podem ser desenvolvidas.</p>
                                                    <p>Se você tem uma ideia de negócio e não sabe por onde começar a implementar, ou se já possui uma empresa, mas está perdido sobre os próximos passos, <strong>participar de um laboratório de negócios pode transformar sua vida e a do seu negócio.</strong></p>
                                                    <p>Tome as rédeas da sua carreira ou crie um negócio sustentável com o que você gosta e sabe fazer. Assim, você vai finalmente experimentar a liberdade de trabalhar com satisfação para <strong>alcançar seu sucesso.</strong></p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-2">
                                                <div className="details details-lab mt_md--30 mt_sm--30">
                                                    
                                                    <p>Um dos principais benefícios de um laboratório de negócios é o poder de ter <strong>soluções inovadoras</strong> criadas em colaboração com sua equipe, além de ter acesso a outros empreendedores, que já tiveram problemas ou necessidades parecidas, para compartilhar experiências.</p>
                                                    <p><strong style={{fontSize: '17px', marginTop: '20px'}}>Com isso é possível:</strong></p>
                                                    <ul>
                                                        <li>Aumentar a produtividade interna</li>
                                                        <li>Melhorar a experiência do cliente</li>
                                                        <li>Criar um modelo de negócio</li>
                                                        <li>Produto ou serviço totalmente novo</li>
                                                        <li>Reduzir custos, já que é possível obter ganhos económicos através da redução de erros.</li>
                                                    </ul>
                                                    <p>Além disso, as medidas personalizadas proporcionam um fortalecimento do comportamento e da cultura empreendedora e assim aumentam o valor da sua marca ao ser vista como uma empresa de projetos inovadores.</p>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-1">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/laboratorio.jpg" style={{objectFit: 'cover', maxHeight:'500px', height:'50vw'}} alt="Hub especializado de negócios em Portugal - São Domingos de Rana"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default LabDetails;