import React from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'}
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image" data-black-overlay="1" style={{background: "#4177ff", backgroundImage: "unset"}}>
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <a href="/home-one">
                                    <img src="/assets/images/logo/Logo SDR - Branco-BUS.svg" alt="Logo images" style={{width:"300px"}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div> */}
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p style={{fontSize: '16px'}}>Copyright © 2022. Todos os direitos reservados.<br/>Força & Grandeza - São Domingos de Rana - Business Academy<br/><a style={{color: 'white', textDecoration: 'underline'}} href="https://eur-lex.europa.eu/legal-content/PT/TXT/PDF/?uri=CELEX:32016R0679&amp;from=PT" target="_blank">RGPD</a> | <a style={{color: 'white', textDecoration: 'underline'}} href="/politicas-privacidade">Políticas de privacidade e cookies</a> | <a  style={{color: 'white', textDecoration: 'underline'}} href="/centro-arbitragem">Centro de Arbitragem</a><br/>
                                <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank"><img src="/assets/images/reclamacoes.png"  style={{marginTop: '10px'}}/></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    )
}
export default FooterTwo;