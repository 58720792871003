import React, { Component } from "react";
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import Accordion1 from "../elements/Accordion";
import ModalVideo from 'react-modal-video';
import Slick from "../blocks/Slick02";
import ContactForm from "../elements/contact/ContactForm2";




class GoldenDetails extends Component{
    
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        document.body.classList.remove('lab-class')
        document.body.classList.remove('hub-class')
        document.body.classList.remove('portugal-class')
        document.body.classList.add('golden-class')
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Helmet  */}
                <Helmet pageTitle='Golden Visa Português' />
                {/* End Helmet  */}

                <Header headerPosition="header--static logoresize logo-hub" logo="all-dark" color="color-black" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--150 pb--190 bg_image bg-image-hub " style={{backgroundImage: 'url(/assets/images/service/golden-visa-portugal.jpg)'}} data-black-overlay="7" >
                    <div className="container">
                        <h2 className="title solange" style={{lineHeight:'1.2'}}>Golden Visa Português</h2>
                        <h4 style={{lineHeight:1.3, color: "white", fontWeight: '300', fontSize: '30px', paddingLeft: '10px', fontStyle: 'italic'}}>Seu sonho começa aqui.</h4>
                    </div>
                </div>
                <div className="container">
                    <div className="row fadein-class acoes-rapidas-hub">
                            <div className="col-lg-3 col-sm-12 offset-lg-1" style={{background: 'linear-gradient(45deg, #ffd082, #c48840)'}}>
                                <a href="#golden-visa">
                                    <h4 style={{color: 'white'}}><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Obter Golden Visa</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: '#9ac23a'}}>
                                <a href="/custos-e-vantagens-portugal">
                                    <h4 style={{color: 'white'}}><i className="fa fa-plus-square" aria-hidden="true"></i> Custos e Portugal</h4>
                                </a>
                            </div>
                            <div className="col-lg-3 col-sm-12" style={{background: 'linear-gradient(315deg, #ffd082, #c48840)'}}>
                                <a href="/contactos">
                                    <h4 style={{color: 'white'}}><i className="fa fa-headphones" aria-hidden="true"></i> Nossos contactos</h4>
                                </a>
                            </div>
                        </div>
                </div>
                {/* End Breadcrump Area */}

                


                {/* Start Page Wrapper */}
                <div className="rn-service-details details-hub-2 ptb--40 fadein-class" id="mais-informacoes">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            
                                            <div className="col-lg-8 col-8 order-2 col-12">
                                                <div className="details">
                                                <h2 className="title solange" style={{marginTop: '15px', lineHeight:'0.9'}}>Portugal</h2>
                                                
                                                    <p className="goldenrod" style={{fontStyle: 'italic', fontWeight: '500'}}>O clima ameno, 3000 horas de sol por ano e 850 km de esplêndidas praias banhadas pelo oceano Atlântico fazem de Portugal um destino perfeito para todas as estações, a poucas horas de viagem de qualquer capital europeia.</p>
                                                    
                                                    <div className="accordion-portugal" style={{marginTop: '20px', marginBottom: '30px'}}>
                                                        <Accordion1/>
                                                    </div>
                                                    <a href="/custos-e-vantagens-portugal" className="custos-button"><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i> Veja todos os custos e vantagens de Portugal aqui</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-4 order-1 col-12" style={{marginBottom: '20px'}}>
                                            <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/bg/previa-video-golden.jpg" alt="Por quê Portugal"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='GU5W1LecyXw' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* End Single Area */}
                                        
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center" style={{paddingTop: '40px'}}>
                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details details-hub mt_md--30 mt_sm--30">
                                                    <h2 className="title solange" style={{fontWeight: '400', textAlign:'left', lineHeight: '1.2'}}>Golden Visa Português</h2>
                                                    <p style={{fontWeight: '300'}}>Oferecemos-lhe a oportunidade de residir em Portugal através de um programa de investimento denominado <span style={{fontSize: '24px', color: 'goldenrod'}} className="goldenrod">“Golden Visa”</span>. O nosso objetivo é encontrar o investimento que melhor se adapte às suas necessidades e ajudar durante esse processo. Com o nosso auxílio, você e sua família terão o melhor futuro disponível, em um país seguro e estável, com acesso a saúde e educação gratuitas para seus filhos.<br/><br/>
                                                    O Golden Visa Português garante de forma permanente o visto de residência e trabalho, para você e sua família, levando em consideração o cumprimento de alguns requisitos básicos.</p>
                                                    <h4 className="title hub-especializado" style={{fontWeight: '400', marginBottom: '30px', fontStyle: 'italic'}}>Requisitos:</h4>
                                                    <Slick/>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-8 col-12 order-2 order-lg-1">
                                                <div className="details details-hub mt_md--30 mt_sm--30" style={{paddingTop: '40px'}}>
                                                <h4 className="title hub-especializado" style={{fontWeight: '400', marginBottom: '30px', fontSize: '22px', fontStyle: 'italic', textAlign: '-webkit-center'}}>Como funciona:</h4>
                                                    <div class="timeline">
                                                        <div class="container-time left-time">
                                                            <div class="content-time">
                                                                <h4>Você entra em contacto connosco <i class="fa fa-comments-o" aria-hidden="true"></i></h4>
                                                                <span>(hoje)</span>
                                                            </div>
                                                        </div>
                                                        <div class="container-time right-time">
                                                            <div class="content-time">
                                                            <h4>Ajustes e assinatura do contrato <i class="fa fa-handshake-o" aria-hidden="true"></i></h4>
                                                            <span>(1º mês)</span>
                                                            </div>
                                                        </div>
                                                        <div class="container-time left-time">
                                                            <div class="content-time">
                                                            <h4>Você e sua família vêm para Portugal <i class="fa fa-plane" aria-hidden="true"></i></h4>
                                                            <span>(2º mês)</span>
                                                            </div>
                                                        </div>
                                                        <div class="container-time right-time">
                                                            <div class="content-time">
                                                            <h4>O investimento será aplicado <i class="fa fa-line-chart" aria-hidden="true"></i></h4>
                                                            <span>(3º mês)</span>
                                                            </div>
                                                        </div>
                                                        <div class="container-time left-time">
                                                            <div class="content-time">
                                                            <h4>Aguardando aprovação <i class="fa fa-spinner" aria-hidden="true"></i></h4>
                                                            <span>(3º mês)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12 order-1 order-lg-2">
                                                <div className="details details-hub mt_md--30 mt_sm--30">
                                                    <img src="/assets/images/passport-portugal-1.png" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-start pt--80 pb--80 contact-form--1 contato-golden-visa" id="golden-visa">
                                        <div className="col-lg-6 order-2 order-lg-1">
                                            <div className="section-title text-left mb--50">
                                                <h4 className="title hub-especializado" style={{fontSize: '30px'}}>Entre em contacto</h4>
                                                <p className="description" style={{fontStyle: 'italic'}}>Viva em um dos países mais seguros do mundo e seja livre para viver a vida com seus entes queridos.</p>
                                            </div>
                                            <div className="form-wrapper">
                                                <ContactForm />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 order-1 order-lg-2">
                                            <div className="thumbnail mb_md--30 mb_sm--30" >
                                                <img src="/assets/images/bg/portugal-golden-contacto.jpg" alt="São Domingos de Rana Business Academy"/>
                                            </div>
                                        </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* End Page Wrapper */}
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default GoldenDetails;