import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="empresas-hub">
        <h3><img src="assets/images/logo/europa-estrela.svg" style={{height: '40px', marginBottom: '10px'}}/>Não pode ser cidadão da UE.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-home" aria-hidden="true"></i><br/>Fazer um investimento qualificado em imóveis ou em fundo de investimento português.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-eur" aria-hidden="true"></i><br/>O investimento precisa estar activo por um mínimo de 5 anos.</h3>
      </div>
      <div className="empresas-hub">
        <h3><i className="fa fa-globe" aria-hidden="true"></i><br/>Os fundos devem vir de fora de Portugal.</h3>
      </div>
      
      <div className="empresas-hub">
        <h3><img src="assets/images/logo/portugal-svg.svg" style={{height: '40px', marginBottom: '10px'}}/>Deve permancer em Portugal 7 dias no primeiro ano e 14 nos seguintes.</h3>
      </div>
      
    </Slider>
  );
}