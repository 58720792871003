import React, { Component } from "react";
// import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";


class Contact extends Component{
    constructor () {
        super()
        document.body.classList.remove('lab-class')
        document.body.classList.remove('hub-class')
        document.body.classList.remove('portugal-class')
        document.body.classList.add('golden-class')
    }
    render(){
        return(
            <React.Fragment>
                {/* <PageHelmet pageTitle='Contact' /> */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="Logo SDR - Branco-BUS.svg"/>



                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--60 ">
                    <div className="container">
                       
                        <div className="row">
                            
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address" style={{minHeight: "100%", paddingBottom:"5px"}}>
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefone</h4>
                                        <p><a href="tel:+351 933 880 787">+351 933 880 787</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                            
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address" style={{minHeight: "100%", paddingBottom:"5px"}}>
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">E-mail</h4>
                                        <p><a href="mailto:geral@sdr-businessacademy.com">geral@sdr-businessacademy.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address" style={{minHeight: "100%", paddingBottom:"5px"}}>
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Localização</h4>
                                        <p>Estrada Nacional 249-4, Km 6<br/>Multi Business Center, Escritório D-11<br/>2785-035 São Domingos de Rana</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                            
                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                
                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation ptb--150 pt--150" style={{background: 'white', paddingTop: '100px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <div style={{textAlign:'-webkit-center', marginBottom: '40px'}}>
                                        <h3 style={{lineHeight: 1.3, textAlign:"center", color:'#4a4a4a',fontSize:'36px', marginBottom: '07px'}}>O nosso grupo empresarial</h3>
                                        {/* <p style={{maxWidth:"650px"}}>Aberto a pequenas, médias e grandes empresas que pretendam tornar-se mais competitivas, mas também a empreendedores que procuram desenvolver a sua ideia de negócio na nossa incubadora.</p> */}
                                        <hr style={{margin:"10px 0px",borderBottom:"2px solid #4a4a4a", width:"80%", maxWidth:'200px'}} /> 
                                    </div>
                                    <BrandTwo />
                                    
                                </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}                


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact